












import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import Container from '@/components/Container.vue';

@Component({
  components: {
    Container
  }
})
export default class PageNotFound extends Vue {}
